import React from 'react';
import { Link } from 'gatsby';
import { CornerDownLeft } from 'react-feather';
import SEO from '../components/SEO/SEO';
import '../styles/main.scss';

const index = () => (
  <>
    <SEO title="Page not found" description="Page not found" lang="en" />
    <section className="not-found">
      <h1 className="title">
        Sorry, this path does not exist{' '}
        <span role="img" aria-label="emoji">
          😞
        </span>
      </h1>
      <p className="cta">
        <Link className="cta__btn" to="/">
          Go back
          <span className="cta__btn-icon">
            <CornerDownLeft color="#f3ca2f" />
          </span>
        </Link>
      </p>
    </section>
  </>
);

export default index;
